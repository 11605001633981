import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { settingServices } from 'src/app/shared/service/setting.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-createaccount',
	templateUrl: './createaccount.component.html',
	styleUrls: ['./createaccount.component.scss']
})
export class CreateAccountComponent implements OnInit {
	public isCollapsed = true;
	signupServiceData: any = {
		"planinterested": null,
		"email": null,
		"companyid": ''
	}
	countryList: any = [];
	form: NgForm;
	code: any;
	regionList: any = [];
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loader: NgxUiLoaderService,
		private toastr: NotificationService,
		private settingService: settingServices,
		private service: AppServices) {

		var encrPlanId = this.route.snapshot.queryParamMap.get('id');
		if (encrPlanId != undefined && encrPlanId != null && encrPlanId != '') {
			this.signupServiceData.planinterested = atob(encrPlanId);
		}
		this.code = this.route.snapshot.queryParamMap.get('code');
		this.getRegions();
		environment.production ? '' : console.log('plan object response', this.signupServiceData);
	}

	ngOnInit(): void {
	}
	getRegions() {
		this.loader.start();
		this.service.getRegions().subscribe(res => {
			this.loader.stop();
			this.regionList = res;
		}, err => {
			this.loader.stop();
		})
	}
	VerifyAccount(): void {
		environment.production ? '' : console.log('Post Signup req : ', JSON.stringify(this.signupServiceData));
		this.loader.start();
		this.service.postSignup(this.signupServiceData).subscribe((res: any) => {
			environment.production ? '' : console.log('Post Signup response : ', res);
			this.loader.stop();
			if (res != null) {
				// Swal.fire('Registration successful!', 'OTP verification code sent on your registered email.', 'success');
				this.router.navigate(['./public/verifyaccount'], { queryParams: { otpdetails: btoa(JSON.stringify(res)) }, replaceUrl: true });
			} else {
				this.toastr.Error("Email already exists please try another email");


			}
		}, err => {
			this.loader.stop();
			environment.production ? '' : console.log('check err', err);
		});
	}

	//#region Navigation Link Redirection
	gotoAbout() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'aboutus' } });
	}
	gotoHome() {
		this.router.navigate(['/public/index']);
	}
	gotoOverview() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'overview' } });
	}
	gotoFeature() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'feature' } });
	}
	gotoPricing() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
	}
	gotoContact() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'contact' } });
	}
	//#endregion Navigation Link Redirection
}
