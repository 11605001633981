<resize-border [dragHolder]="header">
<div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="exampleModalLabel">Assessment</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="needs-validation">
        <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-12">
              <div class="form-group">
                <div class="d-flex w-100">
                  <div class="w-100">
                    <div class="big-label">{{particularData.name}}</div>
                    <div class="description">{{particularData.description}}</div>
                    <div class="m-checkbox mb-0 custom-radio-ml radio-animated" *ngIf="particularData.type == 'choice'">
                      <ng-container *ngFor="let data of particularData.particularLineChoices">
                        <label class="d-block mb-2" for="">
                          <input class="radio_animated" id="" type="radio" [value]="data.description"  name="employee_inputs"
                            [(ngModel)]="particularData.employee_inputs" [class.event-none]="role == 'reviewer'">
                          {{data.description}}
                        </label>
                      </ng-container>
                    </div>
                    <div class="clearfix"></div>
                    <div class="row" *ngIf="particularData.type == 'text'">
                      <div class="form-group col-lg-12">
                        <textarea class="form-control" rows="7" name="employee_inputs" [(ngModel)]="particularData.employee_inputs" [readOnly]="role == 'reviewer'"></textarea>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="w-100" *ngIf="particularData.type == 'multiplechoice'">
                      <ng-container *ngFor="let data of particularData.particularLineChoices">
                        <div class="form-check mb-2">
                          <input class="form-check-input" name="assign" type="checkbox" [value]="data.description"
                          id="data.description" [checked]="data.checked" (click)="assessmentClick(data)"  [class.event-none]="role == 'reviewer'">
                          <label class="form-check-label" for="">
                            {{data.description}}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                    <div class="clearfix"></div>
                    <div class="m-checkbox-inline mb-0 d-flex custom-radio-ml radio-animated" *ngIf="particularData.type == 'yesno'">
                        <label class="d-block" for="">
                          <input class="radio_animated" id="" type="radio" value="yes"  name="employee_inputs"
                            [(ngModel)]="particularData.employee_inputs" [class.event-none]="role == 'reviewer'">
                          Yes
                        </label>
                        <label class="d-block" for="">
                          <input class="radio_animated" id="" type="radio" value="no"  name="employee_inputs"
                            [(ngModel)]="particularData.employee_inputs" [class.event-none]="role == 'reviewer'">
                          No
                        </label>
                    </div>
                    <div class="clearfix"></div>
                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Employee score (Out of {{particularData.weightage}})</label>
                        <bar-rating [(rate)]="particularData.employee_score" [max]="particularData.weightage" [theme]="'square'" name="employee_score" [(ngModel)]="particularData.employee_score" [readOnly]="role == 'reviewer'" ></bar-rating>
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Reviewer's score (Out of {{particularData.weightage}})</label>
                        <div class="ratings">
                          <bar-rating [(rate)]="particularData.reviewer_score" [max]="particularData.weightage" [theme]="'square'"  name="reviewer_score" [(ngModel)]="particularData.reviewer_score" [readOnly]="role == 'employee'"></bar-rating>
                        </div>
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Manager's score (Out of {{particularData.weightage}})</label>
                        <div class="ratings">
                          <bar-rating [(rate)]="particularData.manager_score" [max]="particularData.weightage" [theme]="'square'"  name="manager_score" [(ngModel)]="particularData.manager_score" [readOnly]="true"></bar-rating>
                        </div>
                      </div>
                    </div>
                    
                    <div class="clearfix"></div>
                    <div class="row">
                      <div class="form-group col-lg-6">
                        <label>Reviewer's comments</label>
                        <textarea class="form-control" rows="5" name="reviewer_inputs" [(ngModel)]="particularData.reviewer_inputs" [readOnly]="role == 'employee'"></textarea>
                      </div>
                      <div class="form-group col-lg-6">
                        <label>Manager's comments</label>
                        <textarea class="form-control" rows="5" name="manager_inputs" [(ngModel)]="particularData.manager_inputs" readonly></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </form>
</div>
<div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="postParticularResult()">Save</button>
</div>
</resize-border>