<div id="main" class="main-content-wraper">
    <div class="main-content-inner">
        <div class="main-header">
            <div class="TopNav">
                <div class="container">
                    <ul>
                        <li><i class="fa fa-phone"></i><a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
                            <a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
                        </li>
                        <li><a href="mailto:contactus@sanmolsoftware.com" title="mail us"><i class="fa fa-envelope-open"
                                    style="margin-right:10px;"></i>contactus@sanmolsoftware.com</a></li>
                    </ul>
                </div>
            </div>
            <header class="header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="" title="ezyBooks"><img src="assets/images/applogo.svg"
                                alt="ezyBooks"></a>
                        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                           <span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
                        </button>
                        <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active"><a class="nav-link" title="Home"
                                        (click)="gotoHome()">Home</a></li>
                                <li class="nav-item"><a class="nav-link" title="About" (click)="gotoAbout()">About</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoOverview()"
                                        title="Overview">Overview</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoFeature()"
                                        title="Features">Features</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoPricing()"
                                        title="Pricing">Pricing</a></li>
                                <li class="nav-item"><a class="nav-link" (click)="gotoContact()" title="Contact">Contact
                                        us</a></li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li><a routerLink="/public/createaccount" class="btn btn-outline-primary"
                                        title="Sign Up">Sign Up</a>
                                </li>
                                <li>
                                    <a routerLink="/auth/login" class="btn btn-primary" title="Sign In">Sign In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
        <div class="container">
            <div class="auth-section">
                <div class="row">
                    <div class="col-lg-6 content">
                        <a title="Back" class="bnt btnBack" routerLink="./createaccount">Back</a>
                        <h3>Fill in your account details</h3>
                        <div class="form-group">
                            <label>Full Name</label>
                            <input type="email" class="form-control" name="name" placeholder="Enter the full name" />
                        </div>
                        <div class="form-group">
                            <label>Phone</label>
                            <input type="email" class="form-control" name="phone" placeholder="Enter the phone" />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <div class="passwordToggle">
                                <input [type]="!isPasswordToggle ? 'password' : 'text'" class="form-control" name="password" [(ngModel)]="password" id="password"
                                    placeholder="Password" required>
                                <i class="" (click)="PasswordToggle()"
                                    [ngClass]="isPasswordToggle ? 'fa fa-eye-slash': 'fa fa-eye'"
                                    id="PasswordToggle"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Confirm Password</label>
                            <div class="passwordToggle">
                                <input [type]="!isPasswordToggle ? 'password' : 'text'" class="form-control" name="password" [(ngModel)]="password" id="password"
                                    placeholder="Password" required>
                                <i class="" (click)="PasswordToggle()"
                                    [ngClass]="isPasswordToggle ? 'fa fa-eye-slash': 'fa fa-eye'"
                                    id="PasswordToggle"></i>
                            </div>
                        </div>
                        <button (click)="gotochooseindustry()" class="btn btn-primary btn-block mt-3">Save</button>
                    </div>
                    <div class="col-lg-6">
                        <img src="assets/images/homepage/free-account.png" alt="free account sign up"
                            class="img-fluid auth-img" width="500" height="500" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>