<div class="popover-toggle" [class.toggleshow]="showCapture">
    <a href="javascript:void(0)" class="toggle-icon" (click)="toggleCapture()">
        <i class="fa fa-caret-left" *ngIf="!showCapture"></i>
        <i class="fa fa-caret-right" *ngIf="showCapture"></i>
    </a>
    <a href="javascript:void(0)" class="text-dark" (click)="screenshot()">
        <i class="fa fa-crop capture" aria-hidden="true"></i>
    </a>
</div>
<ngx-photo-editor
  [imageBase64]="imageChangedEvent"
  (imageCropped)="imageCropped($event)"
  [autoCrop]="true"
  [viewMode]="0"
  [resizeToWidth]="500"></ngx-photo-editor>
<div #screen>
    <div class="page-wrapper">
        
        <header>
        <app-header (rightSidebarEvent)="rightSidebar($event)" ></app-header>
    </header>
        <div class="page-body-wrapper">
            <div class="page-sidebar" [class.open]="navServices.collapseSidebar">
                <app-sidebar></app-sidebar>
                <div class="right-sidebar" [class.show]="right_side_bar" id="right_side_bar">
                    <app-right-sidebar></app-right-sidebar>
                </div>
            </div>
            <div class="page-body">
                <app-breadcrumb></app-breadcrumb>
                <main [@animateRoute]="getRouterOutletState(o)">
                    <router-outlet #o="outlet"></router-outlet>
                </main>
                <!-- <router-outlet></router-outlet> -->
            </div>
            <footer class="footer">
                <app-footer></app-footer>
            </footer>
        </div>
    </div>
    <!-- <div class="btn-light custom-theme" [class.rtl]="layoutClass" (click)="clickRtl(layoutType)">{{layoutType}}</div> -->
</div>

    <ng-template #expiryModal let-modal>
        <div class="expiry-popup">
            <div class="expiryclose-button" (click)="closepopup()">
                <i class="fa fa-times"></i>
            </div>
            <div>
        
            <div class="icons-div">
              <img src="assets/images/expiry.png">
            </div>
            <div class="expiry-text">Your ezybooks subscription is about to end soon please renew your subscription</div>
        
            <div class="sub-link">To renew your subscription or to update your subscription <button  (click)="openSubscriptions(paymentcontent)">click here</button></div>
        </div>
        </div>
    </ng-template>

    <ng-template #paymentcontent let-modal>
        <div class="modal-header">
          <h5 class="modal-title f-w-600" id="exampleModalLabel">Balance Due: $75.00</h5>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="needs-validation">
            <div class="form row">
              <div class="form-group col-sm-12">
                <label for="">Payment method</label>
              </div>
              <div class="col-md-6">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [value]="true">
                  <label class="form-check-label" for="inlineRadio1">Saved Cards</label>
                </div>
                <div class="card-list" *ngIf="payType">
                  <div class="card-item" *ngFor="let card of [1,2,3];let i=index">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="exampleRadios-{{i}}" id="exampleRadios-{{i}}"
                         [value]="card.card_id">
                      <label class="form-check-label" for="exampleRadios-{{i}}">
                        1234 5678 9101 1121
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" [value]="false">
                  <label class="form-check-label" for="inlineRadio2">New Card</label>
                </div>
                <div class="card-list p-2" *ngIf="!payType">
                  <div class="form-group">
                    <label for="">Card Holder Name</label>
                    <input name="card_holder" class="form-control" >
                  </div>
                  <div class="form-group">
                    <label for="">Card Number</label>
                    <input name="card_number" class="form-control" placeholder="XXXX-XXXX-XXXX-XXXX">
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Expiry Date</label>
                      <input name="expirydate" class="form-control" placeholder="MM/YY">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">CVV</label>
                      <input name="cvv" class="form-control" placeholder="XXX">
                    </div>
                    <!-- <div class="form-group col-md-6">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                        <label class="form-check-label" for="inlineCheckbox1">Save Card</label>
                      </div>
                    </div> -->
                  </div>
      
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <button class="btn btn-outline-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
          <button type="button" class="btn btn-primary" (click)="saveCard()">Pay</button>
        </div>
      </ng-template>