<div>
    <div class="container p-0">
        <div class="modal-header p-l-20 p-r-20">
            <div class="col-sm-8 p-0">
                <h6 class="modal-title font-weight-bold">QUICK ADD</h6>
            </div>
        </div>
    </div>
    <div class="p-l-15 p-r-15">
        <div class="chat-box">
            <div class="people-list friend-list">
                <ul class="list">
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-user-plus rightsidebar-icon"></i> <div class="name">Add Client</div>
                        </div>
                    </li>
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-file-text-o rightsidebar-icon"></i> <div class="name">New Invoice</div>
                        </div>
                    </li>
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-users rightsidebar-icon"></i> <div class="name">Add Vendor</div>
                        </div>
                    </li>
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-file-text rightsidebar-icon"></i> <div class="name">New Expense</div>
                        </div>
                    </li>
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-file-text-o rightsidebar-icon"></i> <div class="name">Record Receipt</div>
                        </div>
                    </li>
                    <li class="clearfix">                        
                        <div class="about">
                            <i class="fa fa-money rightsidebar-icon"></i> <div class="name">Record Payment</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>