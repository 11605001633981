<div class="mb-lg-5">
 <div class="row text-center">
  <div class="col-md-12 welcome-msg">
    Welcome to Ezybooks
  </div>
 </div>

<!-- <ng-template #expiryModal let-modal>
  <div class="expiry-popup">
    <div class="expiryclose-button" (click)="closepopup()">
      <i class="fa fa-times"></i>
    </div>
    <div>
      <div class="icons-div">
        <img src="assets/images/expiry.png">
      </div>
      <div class="expiry-text">Your ezybooks subscription is about to end soon please renew your subscription</div>
      <div class="sub-link">To renew your subscription or to update your subscription <button
          (click)="gotoSubscriptions()">click here</button>
      </div>
    </div>
  </div>
</ng-template> -->
<ngx-ui-loader></ngx-ui-loader>