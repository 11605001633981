import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './components/auth/auth.module';
import { AuthInterceptor } from './shared/service/auth.interceptor';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CommonModule, DatePipe } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExcelService } from './shared/service/excel.service';
import { FileUploadModule } from 'ng2-file-upload';
import { ToastrModule } from 'ngx-toastr';
import { HomepageModule } from './components/homepage/homepage.module';
import { NgxPrintModule } from 'ngx-print';
import { NgxPrintElementModule } from 'ngx-print-element';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SignalRModule } from 'ng2-signalr';
import { SignalRConfiguration } from 'ng2-signalr';
import { environment } from 'src/environments/environment';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CKEditorModule } from 'ng2-ckeditor';
import { Events } from './shared/service/events.service';
import { NgxCaptureModule } from 'ngx-capture';
import { BnNgIdleService } from 'bn-ng-idle';
import { ConversionService } from './shared/service/conversion.service';
import { GenericprintComponent } from './components/modal/genericprint/genericprint.component';
import { SafePipe } from './shared/service/safepipe';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SmartTableModule } from 'ng2-smart-table'
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { EditassessmentComponent } from './components/editprofile/editassessment/editassessment.component';
import { BarRatingModule } from "ngx-bar-rating";
import { ResizeModule } from './components/modal/resize/resize-border.module';
import { ResizeBorderComponent } from './components/modal/resize/resize-border.component';
import { ChatSignalr } from './components/customerservice/chatsignalr.service';

var fingerprint = getBrowserFingerprint({
  hardwareOnly: true,
  enableWebgl: true
});
export function createConfig(): SignalRConfiguration {
  let config = new SignalRConfiguration();
  config.url = environment.API_URL;//environment.API_URL;
  config.hubName = "chathub";
  // config.qs = { type: '' };
  config.qs = { userId: fingerprint };
  config.logging = true;
  config.executeErrorsInZone = true;
  config.executeEventsInZone = true;
  config.executeStatusChangeInZone = true;
  return config;
}
@NgModule({
  declarations: [AppComponent, GenericprintComponent, SafePipe, EditassessmentComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SignalRModule.forRoot(createConfig), //Uncomment for singalr working
    DashboardModule,
    AuthModule,
    HomepageModule,
    SharedModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    [SweetAlert2Module.forRoot()],
    ToastrModule.forRoot(),
    FileUploadModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxPrintModule,
    NgxPrintElementModule,
    NgxFileDropModule,
    CommonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    AutocompleteLibModule,
    CKEditorModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    NgxCaptureModule,
    Ng2SmartTableModule,
    AngularMultiSelectModule,
    BarRatingModule,
    ResizeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAuoQSNu8Ct-jXbHFdjGLyhYy5Y_mNm3Y4',
      libraries: ['places']
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe,
    ExcelService,
    ChatSignalr,
    Events,
    BnNgIdleService,
    ConversionService,
    AppComponent,
  ],
  exports: [ResizeBorderComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
