<div id="main" class="main-content-wraper">
	<div class="main-content-inner" [ngClass]="isSuccessful ? 'backgroundOverlay' : ''">
		<div class="main-header">
			<div class="TopNav">
				<div class="container">
					<ul>
						<li>
							<i class="fa fa-phone"></i>
							<a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
							<a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
						</li>
						<li>
							<a href="mailto:contactus@sanmolsoftware.com" title="mail us">
								<i class="fa fa-envelope-open" style="margin-right:10px;"></i>
								contactus@sanmolsoftware.com
							</a>
						</li>
					</ul>
				</div>
			</div>
			<header class="header">
				<div class="container">
					<nav class="navbar navbar-expand-lg">
						<a class="navbar-brand" href="" title="ezyBooks">
							<img src="assets/images/applogo.svg" alt="ezyBooks">
						</a>
						<button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
							[attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
							<span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
						</button>
						<div class="navbar-collapse" [ngbCollapse]="isCollapsed">
							<ul class="navbar-nav mr-auto">
								<li class="nav-item active">
									<a class="nav-link" title="Home" (click)="gotoHome()">
										Home
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" title="About" (click)="gotoAbout()">
										About
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" (click)="gotoOverview()" title="Overview">
										Overview
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" (click)="gotoFeature()" title="Features">
										Features
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" (click)="gotoPricing()" title="Pricing">
										Pricing
									</a>
								</li>
								<li class="nav-item">
									<a class="nav-link" (click)="gotoContact()" title="Contact">
										Contact us
									</a>
								</li>
							</ul>
							<ul class="nav navbar-nav navbar-right">
								<li>
									<a routerLink="/public/createaccount" class="btn btn-outline-primary"
										title="Sign Up">
										Sign Up
									</a>
								</li>
								<li>
									<a routerLink="/auth/login" class="btn btn-primary" title="Sign In">
										Sign In
									</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
		</div>
		<div class="container">
			<div class="auth-section DomainTrade">
				<div class="row">
					<div class="col-lg-5 content mb-4">
						<a (click)="back()" title="Back" class="bnt btnBack">Back</a>
						<h3>Migrating to Ezybooks?</h3>
						<br>
						<form name="SysMigrationForm" #f="ngForm">
							<div class="form-group">
								<label for="business_type" class="signup-label">Company type</label>
								<select name="business_type" id="business_type" #business_type="ngModel"
									[(ngModel)]="completeProfileData.business_type" class="form-control"
									[ngClass]="{ 'is-invalid': business_type.invalid && business_type.errors , 'is-valid': business_type.valid}"
									required="">
									<option value="">Select</option>
									<option [ngValue]="data.Value" *ngFor="let data of companyTypes">{{data.Text}}
									</option>
								</select>
								<div *ngIf="business_type.invalid && business_type.errors"
									class="invalid-feedback d-block">
									<div *ngIf="business_type.errors.required">
										Please select Company Type
									</div>
								</div>
							</div>
							<br>
							<div class="form-group">
								<label for="migrating" class="signup-label">Are you migrating to Ezybooks?</label>
								<div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
									<label class="d-block" for="migrating">
										<input class="radio_animated" value="1" id="migrating" type="radio"
											name="migrating" #migrating="ngModel"
											[(ngModel)]="completeProfileData.migrating">
										Yes
									</label>
									<label class="d-block" for="migrating1">
										<input class="radio_animated" value="0" id="migrating1" type="radio"
											name="migrating" #migrating="ngModel"
											[(ngModel)]="completeProfileData.migrating">
										No
									</label>
								</div>
								<div *ngIf="migrating.invalid && migrating.errors" class="invalid-feedback d-block">
									<div *ngIf="migrating.errors.required">
										Please select any one
									</div>
								</div>
							</div>
							<br>
							<div class="form-group">
								<label for="migrating_from" class="signup-label">
									What system you are migrating from?
								</label>
								<input type="text" class="form-control" id="migrating_from" name="migrating_from"
									#migrating_from="ngModel" placeholder="Enter the system name"
									[(ngModel)]="completeProfileData.migrating_from" />
							</div>
							<br>
							<div class="form-group">
								<label for="tax_free" class="signup-label">Is tax free?</label>
								<div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
									<label class="d-block" for="tax_free">
										<input class="radio_animated" value="1" id="tax_free" type="radio"
											name="tax_free" #tax_free="ngModel"
											[(ngModel)]="completeProfileData.tax_free">
										Yes
									</label>
									<label class="d-block" for="tax_free1">
										<input class="radio_animated" value="0" id="tax_free1" type="radio"
											name="tax_free" #tax_free="ngModel"
											[(ngModel)]="completeProfileData.tax_free">
										No
									</label>
								</div>
								<div *ngIf="tax_free.invalid && tax_free.errors" class="invalid-feedback d-block">
									<div *ngIf="tax_free.errors.required">
										Please select any one
									</div>
								</div>
							</div>
							<br>
							<div class="form-group">
								<label for="self_hosting" class="signup-label">Self hosting?</label>
								<div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
									<label class="d-block" for="self_hosting">
										<input class="radio_animated" value="1" id="self_hosting" type="radio"
											name="self_hosting" #self_hosting="ngModel"
											[(ngModel)]="completeProfileData.self_hosting">
										Yes
									</label>
									<label class="d-block" for="self_hosting1">
										<input class="radio_animated" value="0" id="self_hosting1" type="radio"
											name="self_hosting" #self_hosting="ngModel"
											[(ngModel)]="completeProfileData.self_hosting">
										No
									</label>
								</div>
								<div *ngIf="tax_free.invalid && tax_free.errors" class="invalid-feedback d-block">
									<div *ngIf="tax_free.errors.required">
										Please select any one
									</div>
								</div>
							</div>
							<br>
							<div class="form-group">
								<label for="migration_help_needed" class="signup-label">
									Do you need help to migrate data?
								</label>
								<div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
									<label class="d-block" for="migration_help_needed">
										<input class="radio_animated" value="1" id="migration_help_needed" type="radio"
											name="migration_help_needed" #migration_help_needed="ngModel"
											[(ngModel)]="completeProfileData.migration_help_needed">
										Yes
									</label>
									<label class="d-block" for="migration_help_needed1">
										<input class="radio_animated" value="0" id="migration_help_needed1" type="radio"
											name="migration_help_needed" #migration_help_needed="ngModel"
											[(ngModel)]="completeProfileData.migration_help_needed">
										No
									</label>
								</div>
								<div *ngIf="migration_help_needed.invalid && migration_help_needed.errors"
									class="invalid-feedback d-block">
									<div *ngIf="migration_help_needed.errors.required">
										Please select any one
									</div>
								</div>
							</div>
							<br>
							<div class="form-group">
								<label for="referredby" class="signup-label">
									Referred By
								</label>
								<input type="text" class="form-control" id="referredby" name="referredby"
									#referredby="ngModel" placeholder="Enter the referredby name"
									[(ngModel)]="completeProfileData.referredby" />
							</div>
							<br>
							<div class="form-group">
								<label for="referralid" class="signup-label">
									Referral ID
									<i class="fa fa-info-circle searchBtn" container="body"
										ngbTooltip="Referral id is important for referral commission"></i>
								</label>
								<input type="text" class="form-control" id="referralid" name="referralid"
									#referralid="ngModel" placeholder="Enter the referralid"
									[(ngModel)]="completeProfileData.referralid" />
							</div>
							<br>
							<button type="submit" class="btn btn-primary btn-block" [disabled]="!f.valid"
								(click)="SystemSubmit()">
								Submit
							</button>
						</form>
					</div>
					<div class="col-lg-7">
						<div class="slider FeaturesSlider businessSlide">
							<owl-carousel-o [options]="featureSlider">
								<ng-template carouselSlide><img src="assets/images/homepage/featureslide1.png"
										alt="featureslide1" title="featureslide1" loading="lazy">
								</ng-template>
								<ng-template carouselSlide><img src="assets/images/homepage/featureslide2.png"
										alt="featureslide2" title="featureslide1" loading="lazy">
								</ng-template>
								<ng-template carouselSlide><img src="assets/images/homepage/featureslide3.png"
										alt="featureslide3" title="featureslide1" loading="lazy">
								</ng-template>
							</owl-carousel-o>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modal -->
<div class="customModal" [ngClass]="isSuccessful ? 'active' : ''">
	<div class="modalbody">
		<div class="content">
			<h3>Sign up is successfully done</h3>
			<p>
				Please Sign in to your account
			</p>
			<a routerLink="/auth/login" title="Back to Sign in" class="btn btn-primary">Back to Sign in</a>
		</div>
	</div>
</div>
<ngx-ui-loader></ngx-ui-loader>