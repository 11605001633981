import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';
import { NgxCaptureService } from "ngx-capture";
import { tap } from "rxjs/operators";
import {CroppedEvent} from 'ngx-photo-editor';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { screenShotService } from './screenshot.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [transition('* => *', useAnimation(fadeIn, {
      // Set the duration to 5seconds and delay to 2 seconds
      //params: { timing: 3}
    }))])
  ]
})
export class ContentLayoutComponent implements OnInit {
  @ViewChild("screen", { static: true }) screen: any;
  public right_side_bar: boolean;
  public layoutType: string = 'RTL';
  public layoutClass: boolean = false;
  showCapture = false;
  closeResult:any;
  payType = true;
  img:any;
  base64:any={};
  imageObject:any = [];
  imageChangedEvent:any;
  userdata:any;
  appname=environment.APP_NAME;
  @ViewChild('expiryModal') expiryModal: TemplateRef<any>;
  constructor(public navServices: NavService, private captureService: NgxCaptureService, private router:Router, private screenshotService:screenShotService, private sanitizer: DomSanitizer, private modalService:NgbModal, private datePipe:DatePipe) { 
		this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    const currentDate = moment(this.userdata.expiry_date);
		const startDate =  currentDate.format('YYYY-MM-DD');
		const endDate = this.datePipe.transform(new Date(),'yyy-MM-dd');
		const differenceInDays = this.getDaysDifference(new Date(startDate), new Date(endDate));

		if (differenceInDays < 4) {
			setTimeout(() => {
			// this.openExpiryModal();
			}, 1000);
		}
  
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  screenshot() {
    this.captureService
      .getImage(this.screen.nativeElement, true).subscribe(data=> {
        this.imageChangedEvent = data
        environment.production ? '' : console.log(data)
      })
  }

  imageCropped(event: CroppedEvent) {
    this.base64=this.sanitizer.bypassSecurityTrustResourceUrl(event.base64);
    this.screenshotService.screenshots.push({'src':this.base64})
    environment.production ? '' : console.log(this.imageObject)
    
    this.router.navigate(['/snagbugs/addbugs']);
  }

  public rightSidebar($event) {
    this.right_side_bar = $event
  }

  public clickRtl(val) {
    if (val === 'RTL') {
      document.body.className = 'rtl';
      this.layoutClass = true;
      this.layoutType = 'LTR';
    } else {
      document.body.className = '';
      this.layoutClass = false;
      this.layoutType = 'RTL';
    }
  }

  ngOnInit() { }

  toggleCapture() {
    this.showCapture = !this.showCapture;
  }


  closepopup() {
		this.modalService.dismissAll();
	}

  openExpiryModal() {
		this.modalService.open(this.expiryModal, { ariaLabelledBy: 'modal-basic-title', size: 'md', backdrop: 'static', backdropClass:'modal-custom-backdrop' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		});
	}
  // gotoSubscriptions() {
	// 	this.router.navigate(['/companyprofile'], { replaceUrl: true });
  //   this.modalService.dismissAll();
	// }

	getDaysDifference(date1: Date, date2: Date): number {
		const timeDiff = Math.abs(date2.getTime() - date1.getTime());
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysDiff;
	}

  openSubscriptions(paymentcontent) {
    this.modalService.open(paymentcontent, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		});
  }


}
