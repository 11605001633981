import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPrintElementService } from 'ngx-print-element';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-genericprint',
  templateUrl: './genericprint.component.html',
  styleUrls: ['./genericprint.component.scss']
})
export class GenericprintComponent implements OnInit {
  @Input('name') name;
  @Input('id') id;
  @Input('type') type;
  @Input('typename') typename;
  @Input('fromdate') fromdate;
  @Input('todate') todate;
  @Input('status') status;
  @Input('assignto') assignto;
  @Input('params') params;
  ticketsBatchPrintList: any = [];
  userdata: any;
  appname = environment.APP_NAME;
  printURL: any;
  constructor(private modalService: NgbModal, private router: Router, private activeModal: NgbActiveModal, private loader: NgxUiLoaderService, private datepipe: DatePipe, private service: CommonServices, public print: NgxPrintElementService) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
  }
  ngOnInit(): void {
    this.loader.start();
    setTimeout(() => {
      this.loader.stop();
    }, 2000)
    environment.production ? '' : console.log('print actual module', this.typename);

    if (this.type == 'batchprint') {
      this.printURL = environment.API_URL + 'public/batchdownloadpdf?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo + '&vw=' + this.name + '&status=' + this.status + '&fromdate=' + this.fromdate + '&todate=' + this.todate + '&assignto=' + this.assignto;
      // const url = this.router.serializeUrl( this.printURL); //for andy's issue redirecting to new tab for print 04/03/2023
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.type == 'batchprinthtml') {
      this.printURL = environment.API_URL + 'public/batchdownloadhtml?companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo + '&vw=' + this.name + '&status=' + this.status + '&fromdate=' + this.fromdate + '&todate=' + this.todate + '&assignto=' + this.assignto;
      // const url = this.router.serializeUrl( this.printURL); //for andy's issue redirecting to new tab for print 04/03/2023
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'arstatement') {
      this.printURL = environment.API_URL + 'public/printarstmt?id=' + this.id + '&type=' + this.type + '&fromdate=' + this.fromdate + '&todate=' + this.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'printcd') {
      this.printURL = environment.API_URL + 'public/printcd?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'printvd') {
      this.printURL = environment.API_URL + 'public/printvd?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'apstatement') {
      this.printURL = environment.API_URL + 'public/printapstmts?id=' + this.id + '&type=' + this.type + '&fromdate=' + this.fromdate + '&todate=' + this.todate + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'payslip') {
      this.printURL = environment.API_URL + 'public/payslip?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'printbt') { //Batch print
      this.printURL = environment.API_URL + 'public/printbt?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'printp') {
      this.printURL = environment.API_URL + 'public/printp?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      // this.printURL = environment.API_URL + 'public/downloadpdf?id=' + this.id + '&companyid=' + this.userdata.companyid + '&vw=' + this.typename;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'viewprintseq') {
      this.printURL = environment.API_URL + 'public/print4x2view?start=' + this.params.start + '&qty=' + this.params.qty + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else if (this.typename == 'printseq') {
      this.printURL = environment.API_URL + 'public/print4x2sequence?start=' + this.params.start + '&qty=' + this.params.qty + '&printerid=' + this.params.printerid + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo;
      window.open(this.printURL, '_blank');
      this.closeModal();
    } else {
      this.printURL = environment.API_URL + 'public/downloadpdf?id=' + this.id + '&companyid=' + this.userdata.companyid + '&un=' + this.userdata.company_web_token + '&actionby=' + this.userdata.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&logo=' + this.userdata.logo + '&vw=' + this.name;
      window.open(this.printURL, '_blank');
      this.closeModal();
    }
  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

}
