<resize-border  [dragHolder]="header">
<div #header class="modal-header">
    <h5 class="modal-title f-w-600" id="productModalLabel">Print
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <div class="modal-body"> -->
    <object [data]="printURL | safe" type="application/pdf" width="100%" height="592px"></object>
  <!-- </div> -->
  <div class="modal-footer justify-content-center">
    <button class="btn btn-outline-secondary" type="button" data-dismiss="modal" (click)="closeModal()">Close</button>
    <!-- <button class="btn btn-primary" type="button">Save</button> -->
  </div>
</resize-border>