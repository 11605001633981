import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'syncbatches',
    loadChildren: () => import('../../components/integrations/syncbatches/syncbatches.module').then(m => m.SyncbatchesModule),
    data: {
      breadcrumb: 'Sync batches'
    }
  },
  {
    path: 'pull',
    loadChildren: () => import('../../components/integrations/pull/pull.module').then(m => m.PullModule),
    data: {
      breadcrumb: 'Pull'
    }
  },
  {
    path: 'thirdpartyapps',
    loadChildren: () => import('../../components/integrations/thirdparty/thirdparty.module').then(m => m.ThirdpartyModule),
    data: {
      breadcrumb: 'Third Party App'
    }
  },
  {
    path: 'insights',
    loadChildren: () => import('../../components/insights/insights.module').then(m => m.InsightsModule),
    data: {
      breadcrumb: 'Insights'
    }
  },
  {
    path: 'emailers',
    loadChildren: () => import('../../components/tools/emailers/emailers.module').then(m => m.EmailersModule),
    data: {
      breadcrumb: 'Emailers'
    },
  },
  {
    path: 'manualjobs',
    loadChildren: () => import('../../components/tools/manualjobs/manualjobs.module').then(m => m.ManualjobsModule),
    data: {
      breadcrumb: 'Manual Jobs'
    },
  },
  {
    path: 'printsequence',
    loadChildren: () => import('../../components/tools/printsequence/printsequence.module').then(m => m.PrintsequenceModule),
    data: {
      breadcrumb: 'Print Sequence'
    },
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/ebadmin/menu/menu.module').then(m => m.MenuModule),
    data: {
      breadcrumb: 'Menu'
    },
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('../../components/ebadmin/privacypolicy/privacypolicy.module').then(m => m.PrivacypolicyModule),
    data: {
      breadcrumb: 'Privacy Policy'
    },
  },
  {
    path: 'termsofservice',
    loadChildren: () => import('../../components/ebadmin/termsofservice/termsofservice.module').then(m => m.TermsofserviceModule),
    data: {
      breadcrumb: 'Terms of Service'
    },
  },
  {
    path: 'mobileapps',
    loadChildren: () => import('../../components/ebadmin/mobileapps/mobileapps.module').then(m => m.MobileappsModule),
    data: {
      breadcrumb: 'Mobile Apps'
    },
  },
  {
    path: 'userguide',
    loadChildren: () => import('../../components/ebadmin/userguide/userguide.module').then(m => m.UserguideModule),
    data: {
      breadcrumb: 'User Guide'
    },
  },
  {
    path: 'emailtemplates',
    loadChildren: () => import('../../components/ebadmin/emailtemplates/emailtemplates.module').then(m => m.EmailTemplatesModule),
    data: {
      breadcrumb: 'Email Templates'
    },
  },

  //  Eb Admin

  {
    path: 'companies',
    loadChildren: () => import('../../components/ebadmin/companies/companies.module').then(m => m.CompaniesModule),
    data: {
      breadcrumb: 'Company Profile'
    },
  },
  {
    path: 'announcements',
    loadChildren: () => import('../../components/ebadmin/announcements/announcements.module').then(m => m.AnnouncementsModule),
    data: {
      breadcrumb: 'Company Profile'
    },
  },

  {
    path: 'subscriptions',
    loadChildren: () => import('../../components/ebadmin/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    data: {
      breadcrumb: 'Subscriptions'
    },
  },
  {
    path: 'roles',
    loadChildren: () => import('../../components/ebadmin/roles/roles.module').then(m => m.RolesModule),
    data: {
      breadcrumb: 'Roles'
    },
  },
  {
    path: 'salarycomponent',
    loadChildren: () => import('../../components/ebadmin/salarycomponent/salarycomponent.module').then(m => m.SalarycomponentModule),
    data: {
      breadcrumb: 'Salary Components'
    },
  },
  {
    path: 'importdataoptions',
    loadChildren: () => import('../../components/ebadmin/importdataoptions/importdataoptions.module').then(m => m.ImportdataoptionsModule),
    data: {
      breadcrumb: 'Import Data Options'
    },
  },
  {
    path: 'exportdataoptions',
    loadChildren: () => import('../../components/ebadmin/exportdataoptions/exportdataoptions.module').then(m => m.ExportdataoptionsModule),
    data: {
      breadcrumb: 'Export Data Options'
    },
  },
  // profile
  {
    path: 'userprofile',
    loadChildren: () => import('../../components/editprofile/editprofile.module').then(m => m.EditprofileModule),
    data: {
      breadcrumb: 'Profile'
    },
  },
  //changepassword
  {
    path: 'changepassword',
    loadChildren: () => import('../../components/changepassword/changepassword.module').then(m => m.ChangepasswordModule),
    data: {
      breadcrumb: 'Change Password'
    },
  },
  {
    path: 'errorlog',
    loadChildren: () => import('../../components/ebadmin/errorlog/errorlog.module').then(m => m.ErrorlogModule),
    data: {
      breadcrumb: 'Error Log'
    },
  },
];