import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppServices } from '../../service/app.services';
import { DatePipe } from '@angular/common';
import { Events } from '../../service/events.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  appname = environment.APP_NAME;
  pipe = new DatePipe('en-US');
  intervalId;
  today = null;
  userprofile: any;
  constructor(private service: AppServices, private event: Events) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.event.subscribe('event:logout', (event: any) => {
      environment.production ? '' : console.log('event status' + event.data.from);
      if (localStorage.getItem(this.appname + 'userprofile')) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
      }
    });
  }

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.today = this.pipe.transform(Date.now(), 'EEEE, MMMM d, y, h:mm:ss a', this.userprofile.scheduler_timezone);
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }



}
