<div class="container-fluid">
    <div class="d-flex align-items-center footer-username">
        <div class="User-profile"><i class="fa fa-user"></i> {{this.userprofile.name}}</div>
    <div class="row">
        <div class="col-sm-4">
            <p class="mb-0 text-center text-md-left"> {{this.userprofile.companyname}}, Ver 2.0.0.0</p>
        </div>
        <div class="col-sm-4">
            <p class="mb-0 text-center">Powered by <a href="https://sanmolsoftware.com/" target="_blank" title="Sanmol software">Sanmol Software</a></p>
        </div>
        <div class="col-sm-4">
            <p class="mb-0 text-center text-md-right">{{today}}</p>
        </div>
    </div>
</div>
</div>