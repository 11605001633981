import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppServices } from 'src/app/shared/service/app.services';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-systemmigration',
	templateUrl: './systemmigration.component.html',
	styleUrls: ['./systemmigration.component.scss']
})
export class SystemmigrationComponent implements OnInit {
	public isCollapsed = true;
	isSuccessful = false;
	business_type: any;

	businessTypes: any;
	companyTypes: any;
	selectedCompanyType: any;
	otpdetails: any;
	completeProfileData: any = {
		"id": null,
		"business_type": "",
		"tax_free": '0',
		"self_hosting": '0',
		"verified": 1,
		"otp": null,
		"industry": null,
		"migrating": '0',
		"migrating_from": '',
		"migration_help_needed": '0',
		"finyearend": null,
		"planinterested": null,
		"company_name": null,
		"phone": null,
		"email": null,
		"address": null,
		"state_city": null,
		"country": null,
		"currency": null,
		"point_of_contact": null,
		"ververified": 1,
		"referredby": "",
		"referralid": ""
	}

	SysMigrationForm: NgForm;
	companydetails: any;
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private toastr: NotificationService,
		private service: AppServices,
		private loader: NgxUiLoaderService) {
		this.companydetails = JSON.parse(atob(this.route.snapshot.queryParamMap.get('companydetails')));
		this.otpdetails = JSON.parse(atob(this.route.snapshot.queryParamMap.get('otpdetails')));
		// this.completeProfileData.finyearend = this.route.snapshot.queryParamMap.get('finyearend');

		this.service.getBusinessTypes().subscribe((res: any) => {
			if (res != null) {
				this.companyTypes = res;
				environment.production ? '' : console.log('companytypes', JSON.stringify(this.companyTypes));
			}
		}, err => {
			environment.production ? '' : console.log('check err', err);
		});
	}

	ngOnInit(): void {
	}

	featureSlider = {
		loop: true,
		items: 1,
		autoplay: true,
		autospeed: 200,
		dots: true,
		navText: ['', ''],
		responsive: {
			0: {
				items: 1
			},
			400: {
				items: 1
			},
		},
		nav: false
	}

	SystemSubmit() {
		this.completeProfileData.id = this.companydetails.id ? this.companydetails.id : null;
		this.completeProfileData.planinterested = this.companydetails.planinterested;
		this.completeProfileData.company_name = this.companydetails.company_name;
		this.completeProfileData.phone = this.companydetails.phone;
		this.completeProfileData.email = this.companydetails.email;
		this.completeProfileData.finyearend = this.companydetails.finyearend;
		this.completeProfileData.otp = this.companydetails.otp;
		this.completeProfileData.industry = this.companydetails.industry;
		this.completeProfileData.address = this.companydetails.address;
		this.completeProfileData.state_city = this.companydetails.town;
		this.completeProfileData.country = this.companydetails.country;
		this.completeProfileData.currency = this.companydetails.currency;
		this.completeProfileData.point_of_contact = this.companydetails.point_of_contact;
		this.completeProfileData.companyid = this.otpdetails.companyid;
		environment.production ? '' : console.log('post req', JSON.stringify(this.completeProfileData));
		this.loader.start();
		this.service.postSignupComplete(this.completeProfileData).subscribe((res: any) => {
			this.loader.stop();
			if (res != null && res.status_code == '1') {
				res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
				this.router.navigate(['/login'], { replaceUrl: true });
			} else {
				res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
			}
		}, err => {
			this.loader.stop();
			this.toastr.Error("Sorry, something went wrong. Try to sign up again and contact Support if it happens again.");
			environment.production ? '' : console.log('check err', err);
		});
	}

	//#region Navigation Link Redirection
	gotoAbout() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'aboutus' } });
	}
	gotoHome() {
		this.router.navigate(['/public/index']);
	}
	gotoOverview() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'overview' } });
	}
	gotoFeature() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'feature' } });
	}
	gotoPricing() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
	}
	gotoContact() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'contact' } });
	}
	back() {
		this.router.navigate(['./public/chooseindustry'], { queryParams: { otpdetails: JSON.stringify(this.otpdetails) }, replaceUrl: true });
	}
	//#endregion Navigation Link Redirection

}
