import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { HomepageComponent } from '../../homepage/homepage.component';

@Component({
  selector: 'app-accountdetails',
  templateUrl: './accountdetails.component.html',
  styleUrls: ['./accountdetails.component.scss']
})
export class AccountdetailsComponent implements OnInit {
  public isCollapsed = true;
  fullname:any;
  email:any
  password:any;
  public createAccount: FormGroup;
  constructor(private router: Router, private homepage:HomepageComponent,private formBuilder: FormBuilder) { 
    this.createAccountForm();
  }
  createAccountForm(){
    this.createAccount = this.formBuilder.group({
      fullname : [''],
      email : [''],
      password : [''],
      
    })
  }


  ngOnInit(): void {
  }
  isPasswordToggle = false
  PasswordToggle() {
    this.isPasswordToggle = !this.isPasswordToggle
  }
  gotochooseindustry() {
    this.router.navigateByUrl('/public/chooseindustry');
    // if(!this.fullname){
    //   Swal.fire('Please enter your full name');
    //   return false;
    // }
    // if(!this.email){
    //   Swal.fire('Please enter your email id');
    //   return false;
    // }
    // if(!this.password){
    //   Swal.fire('Please enter your password');
    //   return false;
    // }
    // var data = {
    //   fullname: this.fullname,
    //   email: this.email,
    //   password: this.password
    // }
    // environment.production ? '' : console.log(data)
    // localStorage.setItem('chooseIndustryData', JSON.stringify(data));
    // let formValue = JSON.parse(localStorage.getItem('chooseIndustryData'))
    // environment.production ? '' : console.log('choose Industry Data =' + formValue)
    // this.router.navigateByUrl('/public/chooseindustry');
  }
  gotoAbout() {
    this.router.navigate(['/public/index'], {queryParams: {action : 'aboutus'}});
  }
  gotoHome() {
    this.router.navigate(['/public/index']);
  }
  gotoOverview() {
    this.router.navigate(['/public/index'], {queryParams: {action : 'overview'}});
  }
  gotoFeature(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'feature'}});
  }
  gotoPricing(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'pricing'}});
  }
  gotoContact(){
    this.router.navigate(['/public/index'], {queryParams: {action : 'contact'}});
  }
}
