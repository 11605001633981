import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { type } from 'os';
import { AppServices } from 'src/app/shared/service/app.services';
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from 'sweetalert2'
import { MY_DATE_FORMATS } from 'src/app/shared/service/my_date_format';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Events } from 'src/app/shared/service/events.service';
import { ConversionService } from 'src/app/shared/service/conversion.service';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, MicrosoftLoginProvider } from "@abacritt/angularx-social-login";
import { DashboardComponent } from '../../dashboard/dashboard.component'
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	password: any;
	companyid: any;
	userdata: any;
	passActive: boolean = true;
	appname = environment.APP_NAME;
	text: Attr;
	isLogin: boolean = false;
	isLoading: boolean = false;
	companyidList: any;
	findUserData: any;
	selectedCompany: any;
	LoginForm: NgForm;
	tempForm: any = {
		username: '',
		companyid: '',
		password: '',
	}
	socialAuth: any = {
		loginame: '',
		site_name: '',
		auth_token: '',
		companyid: ''
	}
	loginDetails: any;
	isSocialLogin = false;
	constructor(
		private events: Events,
		private datepipe: DatePipe,
		private service: AppServices,
		private router: Router,
		private route: ActivatedRoute,
		private loader: NgxUiLoaderService,
		private conService: ConversionService,
		private authService: SocialAuthService,
		private ngZone: NgZone
	) {
		var from = this.route.snapshot.queryParamMap.get('from');
		if (from != null && from == 'verifyotp') {
			var username = this.route.snapshot.queryParamMap.get('email');
			this.tempForm.username = atob(username);
			var companyid = this.route.snapshot.queryParamMap.get('companyid');
			this.tempForm.companyid = atob(companyid);
			this.findUser();
		}
	}

	owlcarousel = [
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		},
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		},
		{
			title: "Ezybooks.net",
			desc: "one account, access all services and multiple companies",
		}
	]
	owlcarouselOptions = {
		loop: true,
		items: 1,
		dots: true
	};



	ngOnInit() {
		localStorage.clear();
		// var localstorage = localStorage.getItem('userprofile');

	}

	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data) => {
			this.socialAuth.loginame = data.email;
			this.socialAuth.auth_token = data.authToken;
			this.socialAuth.site_name = 'facebook';
			this.findUserByAuthToken();
		});
	}

	signInWithGoogle(): void {
		this.ngZone.run(() => {
			this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
				this.socialAuth.loginame = data.email;
				this.socialAuth.auth_token = data.authToken;
				this.socialAuth.site_name = 'google';
				this.findUserByAuthToken();
			});
		});
	}

	signInWithMicrosoft(): void {
		this.authService.signIn(MicrosoftLoginProvider.PROVIDER_ID).then((data) => {
			this.socialAuth.loginame = data.email;
			this.socialAuth.auth_token = data.authToken;
			this.socialAuth.site_name = 'microsoft';
			this.findUserByAuthToken();
		});
	}

	signOut(): void {
		this.authService.signOut();
	}

	radioChecked(id, i) {
		this.companyidList.forEach(item => {
			item.checked = !item.checked;
		})
	}

	gotoPricing() {
		this.router.navigate(['/public/index'], { queryParams: { action: 'pricing' } });
	}

	findUser() {
		// this.loader.start();
		localStorage.clear();
		this.isLoading = true;
		this.service.findUser(this.tempForm.username).subscribe(res => {
			this.isLoading = false;
			window.localStorage.clear();      //clear all localstorage if availble..
			this.loader.stop();
			this.findUserData = res;
			if (res == undefined || res == null) {
				Swal.fire('', 'User not found, please check your email.', 'error');
				return false;
			} else {
				// localStorage.setItem(this.appname + 'userprofile', JSON.stringify(res));
				this.companyidList = this.findUserData.companyids;
				environment.production ? '' : console.log('this.companyidList : ', this.companyidList);
				if (this.companyidList.length > 0) {
					this.tempForm.companyid = this.companyidList[0].companyid;
				}
				this.isLogin = true;
			}
		}, err => {
			// this.loader.stop();
			this.isLoading = false;
		});
	}

	findUserByAuthToken() {
		// this.loader.start();
		localStorage.clear();
		this.isLoading = true;
		this.isSocialLogin = true;
		var postObj = {
			loginame: this.socialAuth.loginame,
			site_name: this.socialAuth.site_name,
			auth_token: this.socialAuth.auth_token
		}
		this.service.findUserByAuthToken(postObj).subscribe(res => {
			window.localStorage.clear();      //clear all localstorage if availble..
			this.loader.stop();
			this.findUserData = res;
			this.tempForm.username = this.socialAuth.loginame;
			this.isLoading = false;
			if (res == undefined || res == null) {
				this.isSocialLogin = false;
				Swal.fire('', 'User not found, please check your email.', 'error');
				return false;
			} else {
				// localStorage.setItem(this.appname + 'userprofile', JSON.stringify(res));
				this.companyidList = this.findUserData.companyids;
				environment.production ? '' : console.log('this.companyidList : ', this.companyidList);
				if (this.companyidList.length > 0) {
					// this.tempForm.companyid = this.companyidList[0].companyid;
					if (this.companyidList.length == 1) {
						this.socialAuth.companyid = this.companyidList[0].companyid;
						this.doSocialLogin();
					}
				}
			}
		}, err => {
			// this.loader.stop();
			this.isLoading = false;
		});
	}

	doSocialLogin() {
		this.isSocialLogin = true;
		localStorage.clear();
		var postObj = {
			"loginame": this.socialAuth.loginame,
			"password": null,
			"companyid": this.socialAuth.companyid,
			"site_name": this.socialAuth.site_name,
			"auth_token": this.socialAuth.auth_token
		}
		this.isLoading = true;
		this.service.loginByAuthToken(postObj).subscribe(res => {
			this.isLoading = false;
			this.processLoginRes(res);
		}, err => {
			this.isLoading = false;
		});
	}
	doLogin() {
		var postObj = {
			loginame: this.tempForm.username,
			password: this.tempForm.password,
		}
		localStorage.clear();
		this.isLoading = true;
		this.service.login(postObj).subscribe(res => {
			this.isLoading = false;
			if (res.data.isValidUser) {
				this.processLoginRes(res);
			} else {
				Swal.fire('', res.message, 'error');
			}
		}, err => {
			this.isLoading = false;
		});
	}
	changeCompany() {
		this.socialAuth.companyid = this.tempForm.companyid;
		// this.doSocialLogin();
	}
	processLoginRes(data) {
		var res = data.data;
		res.password = this.tempForm.password;
		// set date for as per country format
		MY_DATE_FORMATS.display.dateInput = 'YYYY-MM-DD';//(res.date_format).toUpperCase();
		// res.enddate = this.userdata.enddate;
		res.enddate = moment().add(30, 'days').format('YYYY-MM-DD');
		res.fromdate = moment().subtract(30, 'days').format('YYYY-MM-DD');
		res.postdateformat = 'yyy-MM-dd';
		if (res.time_format == 12) {
			res.displaydatetimeformat = 'yyyy-MM-dd hh:mm:ss a';
		} else {
			res.displaydatetimeformat = 'yyyy-MM-dd hh:mm:ss';
		}
		res.postdatetimeformat = 'yyy-MM-dd HH:mm:ss';
		res.date_format = 'yyy-MM-dd';
		var encrypted = this.conService.set('123456$#@$^@1ERF', JSON.stringify(res));
		var decrypted = this.conService.get('123456$#@$^@1ERF', encrypted);
		localStorage.setItem(this.appname + 'userprofile', JSON.stringify(res));

		this.events.publish('event:logout', {
			data: {
				'from': 'login'
			}
		});
		this.router.navigate(['/dashboard'], { replaceUrl: true });

	}
	passToggle() {
		this.passActive = !this.passActive;
	}
	differentAccount() {
		localStorage.clear();
		this.tempForm = {};
		this.companyidList = [];
		this.isLogin = false;
		this.isLoading = false;
		this.isSocialLogin = false;
		this.router.navigateByUrl('/auth/login');
		// window.location.reload();
	}
}
