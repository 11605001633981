<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 p-0 card-left">
                    <div class="card bg-primary">
                        <a href="javascript:void(0)" routerLink="/" class="svg-icon">
                            <img src="assets/images/applogo.svg">
                        </a>
                        <div class="single-item">
                            <owl-carousel-o [options]="owlcarouselOptions">
                                <ng-container *ngFor="let data of owlcarousel">
                                    <ng-template carouselSlide class="item">
                                        <div>
                                            <h3>{{data.title}}</h3>
                                            <p>{{data.desc}}</p>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <h4><i class="icon-user mr-2"></i>Forgot your password?</h4>
                            <p class="text-dark text-left">
                                Provide your registered email address, We will send your
                                password to your email:
                            </p>
                            <div class="clearfix"></div>
                            <div class="tab-pane fade active show" id="account" role="tabpanel"
                                aria-labelledby="account-tab">
                                <form name="ForgotForm" #f="ngForm" class="form-horizontal auth-form" novalidate="">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email" name="username"
                                            [(ngModel)]="forpassform.username" #username="ngModel"
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                            [ngClass]="{ 'is-invalid': username.invalid && username.errors , 'is-valid': username.valid}"
                                            required="">
                                        <div *ngIf="username.invalid && username.errors"
                                            class="invalid-feedback d-block">
                                            <div class="col-lg-12">
                                                <div *ngIf="username.errors.required">
                                                    Email is required
                                                </div>
                                                <div *ngIf="username.errors?.pattern">
                                                    Valid Email is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-button">
                                        <button class="btn btn-outline-secondary mr-3" routerLink="/login">
                                            Back
                                        </button>
                                        <button class="btn btn-primary" type="Save" [disabled]="!f.valid"
                                            (click)="RetrivePassword()">
                                            Retrieve
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>