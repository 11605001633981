import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from './events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
    providedIn: 'root'
})
export class CommonServices {
    baseUrl = environment.API_URL;
    appname = environment.APP_NAME;
    isLoading: boolean;
    objLogin: modelLogin;
    objComp: modelComp;
    objCompId: any;
    userprofile: any;
    public hero: ''
    public expenseData: ''
    constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.event.subscribe('event:logout', (event: any) => {
            environment.production ? '' : console.log('event status' + event.data.from);
            this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        });
    }

    public headerEditItemData = new BehaviorSubject('');

    //Ticket Category List & sub cat same api need to use -- updated by mayur 4 sept 2022
    getSalesReqItems(binid, deptid, categoryid, subcategoryid, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSalesReqItems?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&binid=' + binid + '&deptid=' + deptid + '&categoryid=' + categoryid + '&subcategoryid=' + subcategoryid + '&searchtxt=' + searchtxt);
    }

    getTicketCategories(parentid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceCategory?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&parentid=' + parentid);
    }

    getAuditLogs(id, pg) {
        return this.http.get(this.baseUrl + 'api/Comman/Gett_AuditLogs?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg);
    }

    getTaxList(type) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTaxList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }

    getClockingCodes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetClockingCodes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    //Ticket Priorities List
    getTicketPriorities() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketPriority?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //enum list
    getComboEnums(name_of_field) {
        return this.http.get(this.baseUrl + 'api/Comman/GetComboEnums?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name_of_field=' + name_of_field);
    }
    //get audit log

    //get party list
    getPartyList(ptype, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetParties?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&partytype=' + ptype + '&searchtxt=' + searchtxt);
    }
    //get document list 
    getDocList(refno) {
        return this.http.get(this.baseUrl + 'api/Comman/GetDocumnets?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno);

    }
    getDocIndexList(refno, description, type) {
        return this.http.get(this.baseUrl + 'api/Documents/Get_DocumentIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&refno=' + refno + '&description=' + description + '&type=' + type);

    }
    // getdoc type{
    getDocType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDocumentType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPayrollPaymentType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPayrollPaymentType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //getBoolean type list as common
    getBooleanList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/yesNoBoolean?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // getticketlist
    getTicketsUsingCustomer(customer) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTickets?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer);
    }
    //Ticket Devices List
    getCustomerDevices(customerid, siteid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerDevices?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&siteid=' + siteid);
    }

    getTechnicianList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTechnicians?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    //Customer info
    getCustomerInfo(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerInfo?customerid=' + customerid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSiteInfo(siteid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSiteInfo?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&siteid=' + siteid);
    }

    getProjJobslist(searchtxt, customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_ProjJobslist?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&customerid=' + customerid);
    }

    getInvOrderslist(searchtxt, customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_InvOrderslist?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&customerid=' + customerid);
    }

    getCustomerList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomers?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getPaymentTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCustomers(name, email, phone) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&email=' + email + '&phone=' + phone);
    }

    //project
    getProjectList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }

    getProjectTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }
    getPOSPaymentMethods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOSPaymentMethods?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.email);
    }
    getCustomerTicketList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTickets?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSOTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomerTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //project
    getCustomerProjectList(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProjects?customerid=' + customerid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCustomerSiteList(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerSites?customerid=' + customerid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSubCategory(cat) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductSubCategory?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&categoryid=' + cat);
    }
    getReceivedVia() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketReceivedVia?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTicketPriority() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketPriority?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getQouteStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getQuoteTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetQuoteTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCurrency() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCurrencies?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getProCategory() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetGoodsCategories?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=')
    }

    // service manager
    getServiceContractPeriods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetServicePeriod?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // binlocation type
    getGetBinType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getWareHouse() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWarehouses?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getsuppliers(tpcode, accno, shortname, name, status) {
        return this.http.get(this.baseUrl + 'api/Suppliers/Get_SupplierIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&tpcode=' + tpcode + '&accno=' + accno + '&shortname=' + shortname + '&name=' + name + '&status=' + status);
    }
    getsuppliersList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSupplierStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getSalesOrerTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSOStatus?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesOrderPricingTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetOrderPricingTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getProjectListByCustomer(custoerid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?username=' + this.userprofile.email + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getAllProList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?username=' + this.userprofile.email + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getProductCatalog(ptype, pricingtype, searchtxt, deptid, categoryid, subcatid, supplierid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductCatalog4Sales?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ptype=' + ptype + '&pricingtype=' + pricingtype + '&searchtxt=' + searchtxt + '&deptid=' + deptid + '&categoryid=' + categoryid + '&subcatid=' + subcatid + '&supplierid=' + supplierid);
    }

    getProductCatalogforPO(searchtxt, supplierid, categoryid, deptid, subcatid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductCatalog4Purchase?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt + '&supplierid=' + supplierid + '&categoryid=' + categoryid + '&deptid=' + deptid + '&subcatid=' + subcatid);
    }

    getAssembliesCatalog() {
        return this.http.get(this.baseUrl + 'api/Comman/GetAssemblyCatalog?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getProductDetailByILC(ilc, searchby, pricingtype) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProdInfo?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc + '&searchby=' + searchby + '&pricingtype=' + pricingtype);
    }
    getPoStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPostatus?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getCountries() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCountries?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAllCountries() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCountries?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getJobList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetJobOpenings?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getDeviceTypeList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDeviceTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    getEmployees() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployees?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTaxConfig(type) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTaxConfigs?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type);
    }
    getCustomerContracts(customerid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCustomerContracts?customerid=' + customerid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPaymentMethods() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentMethods?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCheckBooks(mop) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCheckBooks?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&mop=' + mop);
    }
    getCheckBookNos(chqbokid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetCheckNo?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&chqbokid=' + chqbokid);
    }
    getPaymentTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTicketsByCustomer(custid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTickets?customerid=' + custid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTicketsByDevice(deviceid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceTickets?id=' + deviceid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&fromdate=' + fromdate + '&todate=' + todate);
    }

    getWorkTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWorkType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&val=');
    }
    //product
    getDepartmentList(isservice) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductDept?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + isservice);
    }
    getCategory(dept, isservice) {
        this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProductCategory?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&dept=' + dept + '&isservice=' + isservice);
    }

    getCheckListGroup() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetChecklistGroupTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPoTerms() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getAssignTo() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetAssetUsers?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));


    }
    getPoPriority() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPOPriority?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getPoTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPoTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getSupplierName() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliersName?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSupplierList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSuppliers?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSuppliersList(local, searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSupplierList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&local=' + local + '&searchtxt=' + searchtxt);
    }
    getSchedulerResources() {
        return this.http.get(this.baseUrl + 'api/Scheduler/Gett_Resources?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAssigntoForTask() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetResources?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //notes
    getNotesType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTechNotesType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBOLList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBOLList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getDepositStatusList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDepositStatus?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getAllTicket() {
        return this.http.get(this.baseUrl + 'api/tickets/Gett_AllTickets?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getTagIndex(desc, pg) {
        return this.http.get(this.baseUrl + 'api/Tags/Get_TagIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + desc + '&pg=' + pg);
    }
    getTagByID(id) {
        return this.http.get(this.baseUrl + 'api/Tags/Get_TagByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postTags(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Tags/Post_Tags', data);
    }
    getTags(pg) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTags?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&pg=' + pg);
    }
    getComposeEmail(id, flag) {
        return this.http.get(this.baseUrl + 'api/Emailer/GetComposeEmail?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&flag=' + flag);
    }
    postEmail(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Emailer/Post_Email', data);
    }
    getPrintType() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrintType?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getPlans() {
        return this.http.get(this.baseUrl + 'api/Comman/GetPlans?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get all module report json data
    getIndexReportByType(type, refid, fromdate, todate) {
        return this.http.get(this.baseUrl + 'api/Comman/Export2xls?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&type=' + type + '&fromdate=' + (fromdate == '' ? '' : this.datepipe.transform(new Date(fromdate), 'yyy-MM-dd HH:mm:ss')) + '&todate=' + (todate == '' ? '' : this.datepipe.transform(new Date(todate), 'yyy-MM-dd HH:mm:ss')) + '&refid=' + refid);
    }
    //getbin list
    getBinLocation() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBinLocations?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBSPeriod() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetBSPeriod?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For projecttype index
    getProjectTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProjectTypeIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getProjectTypeIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProjectTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postProjectType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProjectType', data);
    }

    deleteProjectType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProjectType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }


    // For contract type index
    getContractTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_ContractTypeIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getContractTypeIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ContractTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postContractType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ContractType', data);
    }

    deleteContractType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ContractType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Task type index
    getTaskTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_TaskTypeIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getTaskTypeIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_TaskTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postTaskType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_TaskType', data);
    }

    deleteTaskType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_TaskType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Designation type index
    getDesignationIndex(desc) {
        return this.http.get(this.baseUrl + 'api/Master/Get_EmployeeDesignationIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + desc);
    }

    getDesignationIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EmployeeDesignationByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDesignation(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EmployeeDesignation', data);
    }

    deleteDesignation(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EmployeeDesignation?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Assets Location index
    getAssetsLocationIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetLocationIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsLocationIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetLocationByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsLocation(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetLocation', data);
    }

    deleteAssetsLocation(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetLocation?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Assets Condition index
    getAssetsConditionIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetConditionIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsConditionIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetConditionByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsCondition(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetCondition', data);
    }

    deleteAssetsCondition(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetCondition?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    deleteDocument(id) {
        return this.http.delete(this.baseUrl + 'api/Documents/Delete_Document?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Assets Action index
    getAssetsActionIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_AssetActionIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getAssetsActionIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_AssetActionByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postAssetsAction(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_AssetAction', data);
    }

    deleteAssetsAction(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_AssetAction?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Invoice Terms=============
    getInvoiceTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_InvoiceTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }

    getPrinters() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrinters?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getInvoiceTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_InvoiceTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postInvoiceTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_InvoiceTerms', data);
    }

    deleteInvoiceTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_InvoiceTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Quote Terms=============
    getQuoteTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_QuoteTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getQuoteTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_QuoteTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postQuoteTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_QuoteTerms', data);
    }

    deleteQuoteTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_QuoteTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // forExtimateExpirterms
    getEstimateTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_EstimateTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getEstimateTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EstimateTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postEstimateTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EstimateTerms', data);
    }

    deleteEstimateTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EstimateTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For PO Terms=============
    getPOTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_POTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPOTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_POTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPOTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_POTerms', data);
    }
    deletePOTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_POTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For SO Terms=============
    getSOTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_SOTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getSOTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SOTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSOTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SOTerms', data);
    }
    deleteSOTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SOTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Project Terms=============
    getProjectTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProjectTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getProjectTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProjectTermsByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postProjectTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProjectTerms', data);
    }
    deleteProjectTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProjectTerms?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Product Manufacturer=============
    getManufacturerIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Manufacturers?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getManufacturerIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ManufacturerByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postManufacturer(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Manufacturer', data);
    }
    deleteManufacturer(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Manufacturer?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For binLocation warehouse=============
    getWarehouseIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Warehouses?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getWarehouseLst() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetWarehouses?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

    }
    getWarehouseIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_WarehouseByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postWarehouse(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Warehouse', data);
    }
    deleteWarehouse(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Warehouse?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For paybill payment=============
    getPaymentMethodIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_CompanyPaymentMethods?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getPaymentMethodIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Settings/Get_CompanyPaymentMethods?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postPaymentMethod(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanyPaymentMethod', data);
    }
    deletePaymentMethod(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanyPaymentMethod?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for employee department
    getEmpDepartmentIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_EmployeeDepartmentsIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getEmpDepartmentIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_EmployeeDepartmentByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postEmpDepartment(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_EmployeeDepartment', data);
    }
    deleteEmpDepartment(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_EmployeeDepartment?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for document type
    getDocumentTypeIndex(desc, code) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DocumentTypeIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&desc=' + desc + '&code=' + code);
    }
    getDocumentTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DocTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDocumentType(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Post_DocType', val);
    }
    deleteDocumentType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DocType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for cpc type
    getCPCIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CPC?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getCPCByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CPCByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postCPC(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CPC', val);
    }
    deleteCPC(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CPC?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for sales reqyest type
    getDisputeReasonList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetDisputeReasons?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSalesRequestType(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_SalesRequestTypeIndex?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }
    getSalesRequestTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SalesRequestTypeByID/' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSalesRequestType(val) {
        // var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SalesRequestType', val);
    }
    deleteSalesRequestType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SalesRequestType/' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getSalesRequestTypes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalesRequestTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //add location
    getLocationIndex() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyLocations?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postLocation(data) {
        var val = JSON.stringify(data);
        return this.http.post(this.baseUrl + 'api/Settings/Post_CompanySite', val);
    }
    deleteLocation(id) {
        return this.http.delete(this.baseUrl + 'api/Settings/Delete_CompanySite?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //for Product UOM
    getProductUOMIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ProductUOM?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getProductUOMIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ProductUOMByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postProductUOM(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ProductUOM', data);
    }
    deleteProductUOM(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ProductUOM?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Shipping port=============
    getShippingPortIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ShippingPort?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getShippingPortIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ShippingPortByI?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShippingPort(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ShippingPort', data);
    }
    deleteShippingPort(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ShippingPort?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    // For Shipping Agent=============
    getShippingAgentIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_ShippingAgents?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getShippingAgentIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_ShippingAgentByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postShippingAgent(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_ShippingAgent', data);
    }
    deleteShippingAgent(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_ShippingAgent?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Vessels=============
    getVesselsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_Vessels?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getVesselsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_VesselByI?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postVessels(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_Vessel', data);
    }
    deleteVessels(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_Vessel?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For delivery terms=============
    getTermsIndex() {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeliveryTerms?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=');
    }
    getTermsIndexByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeliveryTermByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postDeliveryTerms(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeliveryTerm', data);
    }
    deleteDeliveryTerms(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeliveryTerm?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get user profile
    getUserProfileInfo() {
        return this.http.get(this.baseUrl + 'api/Employees/Gett_ProfileByID?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&employeeid=' + this.userprofile.empid);
    }
    //Complete signup
    postLead2Company(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_Lead2Company', data);
    }
    postLead2Customer(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_Lead2Customer', data);
    }
    getTarrifList(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/GetTariffList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    postAddCard(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Customers/Post_Card', data);
    }
    getChangeSubscriptionDate(customerid, planid, rate) {
        return this.http.get(this.baseUrl + 'api/Settings/Change_Subscription?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid + '&planid=' + planid + '&rate=' + rate);
    }
    getCompanySubscriptions() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanySubscriptions?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    postSubscriptionPayment(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Settings/Post_SubscriptionPayment', data);
    }
    updateCost(val) {
        const data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/PurchaseOrders/Post_UpdatePOCost', data);
    }
    deleteLineItem(id) {
        return this.http.delete(this.baseUrl + 'api/InventoryTransfers/DeleteInventoryTransferEntry?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDueInvList(customer) {
        return this.http.get(this.baseUrl + 'api/Invoices/Get_CustomerInvList?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customer);
    }
    getActiveInactiveStatus() {
        return this.http.get(this.baseUrl + 'api/ListHelper/ActiveInActiveFilter?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getProductBinDetails(prodid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProdBinsData?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getProductPictures(prodid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductPictures?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getProductBin4Adj(prodid) {
        return this.http.get(this.baseUrl + 'api/Comman/GetProductBin4Adj?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&prodid=' + prodid);
    }
    getContact(name) {
        return this.http.get(this.baseUrl + 'api/Comman/GetContacts?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getDevice(name, ilc, type) {
        return this.http.get(this.baseUrl + 'api/Comman/GetDevices?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name + '&ilc=' + ilc + '&type=' + type);
    }
    getSite(name) {
        return this.http.get(this.baseUrl + 'api/Comman/GetSites?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&name=' + name);
    }
    getParticularHdr() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetParticularHdr?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    validateIlc(ilc) {
        return this.http.get(this.baseUrl + 'api/Comman/Check_LookupCodeExist?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ilc=' + ilc);
    }
    getProdUom() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetProdUom?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For sales order type index
    getSOTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_SOTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getSOTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_SOTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postSOType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_SOType', data);
    }

    deleteSOType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_SOTtype?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    getTrackingNotes(ref_id, pg) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_TrackingNotes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&ref_id=' + ref_id + '&pg=' + pg);
    }
    getSupplierContactList(supplierid) {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSupplierContacts?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&supplierid=' + supplierid);

    }
    postTrackingNotes(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Comman/Post_TrackingNotes', data);
    }
    // For customer type index
    getCustomerTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_CustomerTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getCustomerTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_CustomerTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postCustomerType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_CustomerType', data);
    }

    deleteCustomerType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_CustomerType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For Dispute Reason 
    getDisputeReasonIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DisputeReasons?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getDisputeReasonByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DisputeReasonByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDisputeReason(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DisputeReason', data);
    }

    deleteDisputeReason(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DisputeReason?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    // For device type index
    getDeviceTypeIndex(description) {
        return this.http.get(this.baseUrl + 'api/Master/Get_DeviceTypes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&description=' + description);
    }

    getDeviceTypeByID(id) {
        return this.http.get(this.baseUrl + 'api/Master/Gett_DeviceTypeByID?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postDeviceType(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Master/Postt_DeviceType', data);
    }

    deleteDeviceType(id) {
        return this.http.delete(this.baseUrl + 'api/Master/Delete_DeviceType?id=' + id + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getComponentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetSalaryComponent?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getUserGuideParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetUserGuideParent?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    //get guide list
    getGuideList() {
        return this.http.get(this.baseUrl + 'api/Comman/Get_UserGuide?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }

    postReceivedTracking(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_TrackingChequeReceived', data);
    }

    postIssuedTracking(val) {
        var data = JSON.stringify(val);
        return this.http.post(this.baseUrl + 'api/Accounts/Post_TrackingChequeIssued', data);
    }

    getPoliciesParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetPrivacyPolicies?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getServiceParentList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetTermsofService?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getCompLocation() {
        return this.http.get(this.baseUrl + 'api/Settings/Gett_CompanyLocations?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getDesignationList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmployeeDesignations?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getSoftwareList() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyTPIS?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    getBinList(searchtxt) {
        return this.http.get(this.baseUrl + 'api/Comman/Get_Binlist?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + searchtxt);
    }
    getEmailTemplateCodes() {
        return this.http.get(this.baseUrl + 'api/ListHelper/GetEmailTemplateCodes?actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
    }
    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
        }
        return throwError('Something went wrong. Please try again later.');
    }


}
