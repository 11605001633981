import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { throwError, Observable } from 'rxjs';
import { modelLogin, modelComp, TokenParams } from '../../components/auth/login/modelLogin';
import { catchError } from 'rxjs/operators';
import { Events } from './events.service';
import { DatePipe } from '@angular/common';

// import { ToastController } from '@angular/common';
// import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class AppServices {
  baseUrl = environment.API_URL;
  appname = environment.APP_NAME;
  isLoading: boolean;
  objLogin: modelLogin;
  objComp: modelComp;
  objCompId: any;
  userprofile: any;
  public hero: ''
  public expenseData: ''
  constructor(private http: HttpClient, private event: Events, private datepipe: DatePipe) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.event.subscribe('event:logout', (event: any) => {
      environment.production ? '' : console.log('event status' + event.data.from);
      this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    });
  }

  // For signup customer
  findUser(loginame) {
    return this.http.get(this.baseUrl + 'api/Login/FindUser?loginame=' + loginame);
  }

  // For signup customer socialmedia
  findUserByAuthToken(loginObj) {
    const data = 'api/Login/FindUserByAuthToken';
    return this.http.post<modelLogin>(environment.API_URL + data, loginObj)
      .pipe(catchError(this.handleError));

    // return this.http.get(this.baseUrl + 'api/Login/FindUserByAuthToken?loginame=' + loginame + '&site_name=' + site_name + '&auth_token=' + auth_token);
  }

  loginByAuthToken(loginObj: any): Observable<any> {
    const data = 'api/Login/LoginUserByAuthToken';
    return this.http.post<modelLogin>(environment.API_URL + data, loginObj)
      .pipe(catchError(this.handleError));
  }

  login(loginObj: any): Observable<any> {
    const data = 'api/Login/LoginUser';
    return this.http.post<modelLogin>(environment.API_URL + data, loginObj)
      .pipe(catchError(this.handleError));
  }
  loginEncrypted(data) {
    const val = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'api/Login/LoginUser', val);
  }
  //Reset password
  resetPassword(data) {
    return this.http.post(this.baseUrl + 'api/Login/Resetpassword', data)
  }
  //Change Password 
  postChangePass(data) {
    const val = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'api/Users/ChangePassword', val)
  }
  //Signup services
  getSignupPlans() {
    return this.http.get(this.baseUrl + 'api/Public/GetPlans');
  }

  getRegions() {
    return this.http.get(this.baseUrl + 'api/Public/GetRegions');
  }

  postSignup(data) {
    return this.http.post(this.baseUrl + 'api/Public/Post_Signup', data);
  }

  postSignupComplete(data) {
    var val = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'api/Public/Post_SignupComplete', val);
  }

  getBusinessTypes() {
    return this.http.get(this.baseUrl + 'api/Public/GetBusinessTypes');
  }

  getTradeTypes() {
    return this.http.get(this.baseUrl + 'api/Public/GetBusinessDomain');
  }

  getMenuList() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/Menu/Get_Menus?email=' + this.userprofile.email + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  // customer
  getCustomerInfo(customerid) {

    return this.http.get(this.baseUrl + 'api/Comman/GetCustomerInfo?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&customerid=' + customerid);


  }
  //product
  getProductList() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/prs/Gett_products?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&searchtxt=' + '');
  }
  getCategory(dept) {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + false + '&dept=' + dept);
  }


  getSubCategory(categoryid) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductSubCatgeory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&categoryid=' + categoryid);
  }


  //project
  getProjectList() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProjects?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&username=' + this.userprofile.username);
  }
  getProjectTypes() {

    return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getProductType() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getProjectStatus() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProjectStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getProjectDetails(id) {
    return this.http.get(this.baseUrl + 'api/Projects/Gett_ProjectByID?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  //project - bug
  getBugList() {
    return this.http.get(this.baseUrl + 'api/Snags/Get_SnagIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBugStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetSnagStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getTaskListByProject(proj) {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTasks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&proj=' + proj);
  }
  deleteBug() {
    return this.http.delete(this.baseUrl + 'api/bugs/Deletet_bugs?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBugType() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetSnagType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getBugPriority() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetSnagPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }


  // project-teams 

  postResources() {

  }

  // project-expense 



  getExpStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetExpenseStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getExpType() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetExpenseType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getCurrency() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetCompanyCurrencies?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getExpsenseDetails(id) {
    return this.http.get(this.baseUrl + 'api/expenses/Gett_expenses?id=' + id + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  //employee
  getDepartmentList(isservice) {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetProductDept?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&isservice=' + isservice);
  }
  getGenderList() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetGender?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getEmpType() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpType?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getEmpDesignation() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetDesignations?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getGetPayType() {

    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPayTypes');
  }
  getEmpStatus() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetEmpStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }

  // task

  getTaskList() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTasks?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  //add task

  getTaskReviewer() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTaskReviewers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getTaskTypeist() {
    this.userprofile = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTaskTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }


  //  project-expense-PO 
  getShiptoList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetShipto?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getPoTerms() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPOTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getPoPriority() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPOPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getPoTypes() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPoTypes?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getPoStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPostatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }


  // prpject -- Invoice
  getInvList(invno) {
    return this.http.get(this.baseUrl + 'api/PurchaseInvoices/Get_PIIndex?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&invno=' + invno);
  }
  getInvStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getInvTerms() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetPaymentTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  // project -- qoute 

  getQouteList(quoteno) {
    return this.http.get(this.baseUrl + 'api/WebQuotes/Gett_quotehdr?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss') + '&quoteno=' + quoteno);
  }
  postQoute(val) {
    const data = JSON.stringify(val);
    return this.http.post(this.baseUrl + 'api/WebQuotes/Postt_quotehd', data);

  }
  getQouteStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getQuoteTerms() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetQuoteTerms?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getticketStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getticketPriority() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketPriority?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getticketCat() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetServiceCategory?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }
  getticketAssginToList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetTicketAssignto?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  // binlocation list
  getGetBinLocationList() {
    return this.http.get(this.baseUrl + 'api/api/WebInventoryItems/ScanBinLocations?binlocation=&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getInternalDeptBin() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInternalDeptBins?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  //customer


  // supplier
  getSupplierStatus() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetInvStatus?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getLocalSuppler() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetLocalSuppliers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  //ship  too
  getCountryList() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetCountries?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getAccounts(acctype, accgroup) {
    return this.http.get(this.baseUrl + 'api/Comman/GetAccounts?acctype=' + acctype + '&accgroup=' + accgroup + '&companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));

  }
  getGlAccounts() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetGLAccounts?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }

  getCustomers() {
    return this.http.get(this.baseUrl + 'api/ListHelper/GetCustomers?companyid=' + this.userprofile.companyid + '&actionby=' + this.userprofile.email + '&actiondate=' + this.datepipe.transform(new Date(), 'yyy-MM-dd HH:mm:ss'));
  }



  //insights
  // getGraphDashboard() {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/Gett_dashboard?companyid=' + this.userprofile.companyid + '&companyname=' + this.userprofile.companyname)
  // }
  // getPLdata(yr) {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/GetPL?yr=' + yr + '&companyid=' + this.userprofile.companyid)
  // }
  // getSalesTrend(yr) {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/GetSalesTrend?yr=' + yr + '&companyid=' + this.userprofile.companyid)
  // }
  // getTopCustomers(yr) {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/GetTopCustomers?yr=' + yr + '&companyid=' + this.userprofile.companyid)
  // }
  // getIncome(val) {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/GetInc?val=' + val + '&companyid=' + this.userprofile.companyid)
  // }
  // getTotalExp(val) {
  //   return this.http.get(this.baseUrl + 'api/WebInsights/GetExp?val=' + val + '&companyid=' + this.userprofile.companyid)


  // }
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.message}`);
    }
    return throwError('Something went wrong. Please try again later.');
  }

}
