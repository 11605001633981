import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonServices } from 'src/app/shared/service/common.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { T } from '@angular/cdk/keycodes';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ProfileServices } from '../profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editassessment',
  templateUrl: './editassessment.component.html',
  styleUrls: ['./editassessment.component.scss']
})
export class EditassessmentComponent implements OnInit {
  appname = environment.APP_NAME;
  userdata: any;
  arr: any = [];
  @Input('item') item;
  @Input('role') role;
  particularData: any;
  constructor(private modalService: NgbModal,
    private router: Router,
    private toaster: NotificationService,
    private datepipe: DatePipe, private service: ProfileServices, private toastr: NotificationService, private loader: NgxUiLoaderService, private commonService: CommonServices, private cd: ChangeDetectorRef, private activeModal: NgbActiveModal) {
    this.userdata = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
    this.commonService.headerEditItemData.next('');
  }

  ngOnInit(): void {
    if (this.item) {
      this.particularData = this.item;
    }

    if (this.particularData.type == 'multiplechoice' && this.particularData.employee_inputs != null && this.particularData.employee_inputs != undefined) {
      var commonstring = this.particularData.employee_inputs;
      var arr = [];
      arr = commonstring.split(',');
      this.particularData.particularLineChoices.forEach(data => {
        arr.forEach(data2 => {
          if (data.description == data2) {
            data.checked = true;
          }
        })
      })

    }

  }

  assessmentClick(data) {
    data.checked = !data.checked;
    if (data.checked == true) {
      this.particularData.particularLineChoices.forEach(res => {
        if (res.checked) this.arr.push(res.description);
      })

      var arr = this.arr.toString();
      this.particularData.employee_inputs = arr;
      console.log(this.particularData.employee_inputs);
    } else if (data.checked == false) {
      this.particularData.particularLineChoices.forEach(res => {
        if (res.checked) this.arr.push(res.description);
      })
      var commonstring = this.particularData.employee_inputs;
      var arr2 = [];
      arr2 = commonstring.split(',');
      const index = arr2.indexOf(data.description);
      if (index > -1) {
        arr2.splice(index, 1);
      }
      var arr3 = arr2.toString();
      this.particularData.employee_inputs = arr3;
    }

  }

  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  postParticularResult() {
    this.particularData.updateby = this.userdata.email,
      this.particularData.updatedate = this.datepipe.transform(new Date(), this.userdata.postdatetimeformat),
      console.log(this.particularData);
    this.loader.start();

    this.service.postEmployeeParticularEntry(this.particularData).subscribe((res: any) => {
      this.loader.stop();
      if (res != null) {
        if (res.status_code == "1") {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'success') : this.toastr.Success(res.message);
        } else {
          res.msg_type == "sweetalert" ? Swal.fire('', res.message, 'error') : this.toastr.Error(res.message);
          return;
        }
      }
      this.particularData = {};
      this.closeModal();
    }, err => {
      this.loader.stop();
      this.toaster.Error('Error while saving record')
    })
  }

}
