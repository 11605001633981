<div id="main" class="main-content-wraper">
    <div class="main-content-inner">
        <div class="main-header">
            <div class="TopNav">
                <div class="container">
                    <ul>
                        <li>
                            <i class="fa fa-phone"></i>
                            <a href="tel:3453255469" title="call us">+1(345) 325 5469</a> &
                            <a href="tel:+13456406292" title="call us"> +1(345) 640 6292</a>
                        </li>
                        <li>
                            <a href="mailto:contactus@sanmolsoftware.com" title="mail us">
                                <i class="fa fa-envelope-open" style="margin-right:10px;"></i>
                                contactus@sanmolsoftware.com
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <header class="header">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" href="" title="ezyBooks"><img src="assets/images/applogo.svg"
                                alt="ezyBooks"></a>
                        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                            <span class="" [ngClass]="isCollapsed?'fa fa-bars':'fa fa-times'"></span>
                        </button>
                        <div class="navbar-collapse" [ngbCollapse]="isCollapsed">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item active">
                                    <a class="nav-link" title="Home" (click)="gotoHome()">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" title="About" (click)="gotoAbout()">About</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" (click)="gotoOverview()" title="Overview">Overview</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" (click)="gotoFeature()" title="Features">Features</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" (click)="gotoPricing()" title="Pricing">Pricing</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" (click)="gotoContact()" title="Contact">Contact us</a>
                                </li>
                            </ul>
                            <ul class="nav navbar-nav navbar-right">
                                <li><a routerLink="/public/createaccount" class="btn btn-outline-primary"
                                        title="Sign Up">Sign Up</a>
                                </li>
                                <li>
                                    <a routerLink="/auth/login" class="btn btn-primary" title="Sign In">Sign In</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
        <div class="container">
            <div class="auth-section">
                <div class="row">
                    <div class="col-lg-5 content">
                        <a (click)="back()" title="Back" class="bnt btnBack">Back</a>
                        <br>
                        <div class="clearfix"></div>
                        <form name="myindustry" #f="ngForm" class="mt-3">
                            <div class="form-group">
                                <label class="required" for="company_name"> Company Name</label>
                                <input type="text" class="form-control" name="company_name" #company_name="ngModel"
                                    id="company_name" [(ngModel)]="IndustryForm.company_name"
                                    placeholder="Company Name"
                                    [ngClass]="{ 'is-invalid': company_name.invalid && company_name.errors , 'is-valid': company_name.valid}"
                                    required="">
                                <div *ngIf="company_name.invalid && company_name.errors"
                                    class="invalid-feedback d-block">
                                    <div *ngIf="company_name.errors.required">
                                        Company name is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="required" for="phone">Phone</label>
                                <input type="tel" class="form-control" name="phone" #phone="ngModel"
                                    [(ngModel)]="IndustryForm.phone" placeholder="Phone#" pattern="[- +()0-9]+"
                                    minlength="8"
                                    [ngClass]="{ 'is-invalid': phone.invalid && phone.errors , 'is-valid': phone.valid}"
                                    required="">
                                <div *ngIf="phone.invalid && phone.errors" class="invalid-feedback d-block">
                                    <div *ngIf="phone.errors.required">
                                        Phone is required
                                    </div>
                                    <div *ngIf="phone.errors.minlength">
                                        Must have 8 digits
                                    </div>
                                    <div *ngIf="phone.errors.pattern">
                                        Please insert a valid phone number like "+1345232323"
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="IndustryForm.email==null">
                                <label class="required" for="email">Email</label>
                                <input readonly type="email" class="form-control" name="email" #email="ngModel"
                                    [(ngModel)]="IndustryForm.email" placeholder="Email ID"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                    [ngClass]="{ 'is-invalid': email.invalid && email?.errors , 'is-valid': email.valid}"
                                    required="" />
                                <div *ngIf="email.invalid && email.errors" class="invalid-feedback d-block">
                                    <div *ngIf="email.errors.required">Email is required</div>
                                    <div *ngIf="email.errors?.pattern">Valid Email is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="required" for="address">Address</label>
                                <input type="text" class="form-control" name="address" #address="ngModel"
                                    [(ngModel)]="IndustryForm.address" placeholder="Address"
                                    [ngClass]="{ 'is-invalid': address.invalid && address.errors , 'is-valid': address.valid}"
                                    required="" />
                                <div *ngIf="address.invalid && address.errors" class="invalid-feedback d-block">
                                    <div *ngIf="address.errors.required">
                                        Address is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="required" for="country">Country</label>
                                <select class="form-control" class="form-control" name="country" #country="ngModel"
                                    [(ngModel)]="IndustryForm.country"
                                    [ngClass]="{ 'is-invalid': country.invalid && country.errors , 'is-valid': country.valid}"
                                    required="">
                                    <option value="">Select Country--</option>
                                    <option *ngFor="let des of countryList" [value]="des.Value">{{des.Text}}</option>
                                </select>
                                <div *ngIf="country.invalid && country.errors" class="invalid-feedback d-block">
                                    <div *ngIf="country.errors.required">
                                        Please select Country
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="required" for="town">Town</label>
                                <input type="text" class="form-control" name="town" #town="ngModel"
                                    [(ngModel)]="IndustryForm.town" placeholder="Town"
                                    [ngClass]="{ 'is-invalid': town.invalid && town.errors , 'is-valid': town.valid}"
                                    required="" />
                                <div *ngIf="town.invalid && town.errors" class="invalid-feedback d-block">
                                    <div *ngIf="town.errors.required">
                                        Town is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="required" for="point_of_contact">Point of Contact</label>
                                <input type="text" class="form-control" name="point_of_contact"
                                    #point_of_contact="ngModel" [(ngModel)]="IndustryForm.point_of_contact"
                                    placeholder="Point of Contact"
                                    [ngClass]="{ 'is-invalid': point_of_contact.invalid && point_of_contact.errors , 'is-valid': point_of_contact.valid}"
                                    required="" />
                                <div *ngIf="point_of_contact.invalid && point_of_contact.errors"
                                    class="invalid-feedback d-block">
                                    <div *ngIf="point_of_contact.errors.required">
                                        Point of Contact is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="industry">What industry are you in?</label>
                                <select [(ngModel)]="IndustryForm.industry" class="form-control" name="industry"
                                    #industry="ngModel" id="industry"
                                    [ngClass]="{ 'is-invalid': industry.invalid && industry.errors , 'is-valid': industry.valid}"
                                    required="">
                                    <option value="">Select</option>
                                    <option [ngValue]="data.Value" *ngFor="let data of listofIndustry">{{data.Text}}
                                    </option>
                                </select>
                                <div *ngIf="industry.invalid && industry.errors" class="invalid-feedback d-block">
                                    <div *ngIf="industry.errors.required">
                                        Please select Industry
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="clearfix"></div>
                                <label for="finyearend">Financial year end</label>
                                <input class="form-control" type="date" id="finyearend" name="finyearend"
                                    [(ngModel)]="IndustryForm.finyearend" #finyearend="ngModel"
                                    [ngClass]="{ 'is-invalid': finyearend.invalid && finyearend.errors , 'is-valid': finyearend.valid}"
                                    required="">
                                <div *ngIf="finyearend.invalid && finyearend.errors" class="invalid-feedback d-block">
                                    <div *ngIf="finyearend.errors.required">
                                        Financial year end is required
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block mt-3" (click)="SubmitIndustry()"
                                [disabled]="!f.valid">
                                Next
                            </button>
                        </form>

                    </div>
                    <div class="col-lg-7">
                        <img src="assets/images/homepage/adminframe.png" alt="admin frame" class="img-fluid"
                            style="width:100%" width="500" height="500" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>