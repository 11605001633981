import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { formatCurrency, formatDate, formatNumber, formatPercent } from "@angular/common";
import { BroadcastEventListener, ConnectionStatus, IConnectionOptions, SignalR, SignalRConnection } from 'ng2-signalr';
import { Router } from '@angular/router';
import { Events } from 'src/app/shared/service/events.service';
@Injectable()
export class ChatSignalr {
    public connectionIsEstablished = false;
    userdata: any;
    supportchatobj: any;
    appname = environment.APP_NAME;
    userData: any;
    conx: any;
    public subscribeMsg: any;
    messages: any = [];
    constructor(
        private _signalR: SignalR,
        private events: Events,
        private router: Router) {
        this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        this.connect();
        setTimeout(() => {
            this.subscribeListners();
        }, 4000);
        environment.production ? '' : console.log('in signalr service');
    }

    connect() {
        return;
        this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
        let o: IConnectionOptions = {};
        if (this.userData != undefined && this.userData != null) {
            o.qs = { userId: this.userData.uuid, type: 'staff' };
        }
        // this.conx = this._signalR.connect(o);
        setTimeout(() => {
            this.conx = this._signalR.createConnection(o);
            this.conx.start().then((c) => {
                this.subscribrLocationListner();
                // this.conx.invoke('JoinGroup', 'staff_group_' + this.userData.companyid);
                environment.production ? '' : console.log('connection status', c);
                this.connectionIsEstablished = true;
                this.events.publish('event:connectionStatus', {
                    c
                });
            });
        }, 3000);
    }

    subscribeListners() {
        return;
        this.conx.status.subscribe((s: any) => {
            environment.production ? '' : console.log('connection status :' + s.name)
            this.connectionIsEstablished = s.value;
            if (s.value == 0) {
                setTimeout(this.connect, 3000);
            }
        });
        let listener = this.conx.listenFor('onMessageReceived');
        listener.subscribe((data) => {
            environment.production ? '' : console.log('signalr ', JSON.stringify(data));
            data.SentTimeStamp = this.customDateFormat(new Date(parseInt(data.SentTimeStamp)), 'hh:mm a');
            if (data.FromId == this.userData.uuid) {
                data.isMine = true;
            } else {
                data.isMine = false;
            }
            this.events.publish('event:newMessage', {
                data
            });
        });
    }
    subscribrLocationListner() {
        let sendLocaitonListener = this.conx.listenFor('sendLocaiton');
        sendLocaitonListener.subscribe((data) => {
            environment.production ? '' : console.log('signalr ', JSON.stringify(data));
            // data.SentTimeStamp = this.customDateFormat(new Date(parseInt(data.SentTimeStamp)), 'hh:mm a');
            this.events.publish('event:newLocation', {
                data
            });
        });
    }
    joinLocationReceiveGroup() {
        environment.production ? '' : console.log('join staff group');
        this.conx.invoke('JoinGroup', 'staff_group_' + this.userData.companyid).then((data: any) => {
            environment.production ? '' : console.log(data);
            this.events.publish('event:locationupdate', {
                data: data
            });
        });
    }
    //#region Join PublicChat from Homepage
    joinSupportChat(room) {
        this.conx.invoke("joinSupportChat", room).then((data: any) => {
            // environment.production ? '' : console.log(data);
            this.events.publish('event:joinSupportChat', {
                data: data
            });
        });
    }

    getRooms() {
        this.conx.invoke("GetRooms").then((data: any) => {
            // environment.production ? '' : console.log(data);
            this.events.publish('event:getRooms', {
                data: data
            });
        });
    }

    getCurrentUser() {
        this.conx.invoke("CurrentUserId").then((data: any) => {
            // environment.production ? '' : console.log(data);
            this.events.publish('event:getCurrentUser', {
                data: data
            });
        });
    }

    openChat(uuid) {
        this.conx.invoke("OpenChat", uuid).then((data: any) => {
            environment.production ? '' : console.log(data);
            // this.events.publish('event:getCurrentUser', {
            //     data: data
            // });
        });
    }

    getMessageHistory(uuid) {
        this.conx.invoke("GetMessageHistory", uuid, 2).then((data: any) => {
            environment.production ? '' : console.log(JSON.stringify(data));
            this.userData = JSON.parse(localStorage.getItem(this.appname + 'userprofile'));
            var lastdate = '';
            data.forEach(item => {
                item.avatar = item.avatar != '' ? environment.API_URL + '' + item.avatar : 'https://placeimg.com/140/140/any';
                item.date = this.customDateFormat(new Date(parseInt(item.SentTimeStamp)), 'EEEE, MMMM dd yyyy')
                if (lastdate != item.date) {
                    lastdate = item.date;
                } else {
                    item.date = '';
                }
                item.SentTimeStamp = this.customDateFormat(new Date(parseInt(item.SentTimeStamp)), 'hh:mm a')
                if (this.userData != undefined && this.userData != null && item.FromId == this.userData.uuid) {
                    item.isMine = true;
                } else {
                    item.isMine = false;
                }
            });
            this.events.publish('event:getMessageHistory', {
                data: data
            });
        });
    }
    sendLocation(){
        // setInterval( function() { funca(10,3); }, 500 );
    }
    sendMsg(from, data) {
        if (!this.connectionIsEstablished) {
            return false;
        }
        var methodName = from == 'support' ? 'SupportMessage' : 'ClientMessage';
        this.conx.invoke(methodName, data).then((info: any) => {
            environment.production ? '' : console.log('sent message status ' + info);
        });
    }
    //#endregion Join PublicChat from Homepage

    customDateFormat(date: Date, format: string) {
        return formatDate(date, format, 'en-US')
    };
}