import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from '../../shared/shared.module';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  MicrosoftLoginProvider
} from '@abacritt/angularx-social-login';
const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'login' //you can use any name here
};
@NgModule({
  declarations: [LoginComponent, ForgotPassComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SharedModule,
    FormsModule,
    NgxUiLoaderModule,
    SocialLoginModule
  ],
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '39955721963-4ekdn3e1j3trj23p3j7rgqqb2ibi3s7t.apps.googleusercontent.com',
            googleLoginOptions
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('576996704420232')
        },
        {
          id: MicrosoftLoginProvider.PROVIDER_ID,
          provider: new MicrosoftLoginProvider('e2dcae27-39a4-4b29-a59e-720c2774a857', {
            redirect_uri: 'https://eb.sanmolapps.com/login',
            logout_redirect_uri: 'https://eb.sanmolapps.com/login'
          })
        }
      ],
      onError: (err) => {
        console.error(err);
      }
    } as SocialAuthServiceConfig,
  }]
})
export class AuthModule {

}
